<template>
    <div>
        <div class="row mt-5">
            <div class="col-md-6 my-5">
                <div class="card card-custom">
                    <div class="card-body row">
                        <div class="col-md-6">
                            <label for="refund_method">
                                <span>
                                    {{ $t('cancelPartialReservation.methodOfRefund') }}
                                </span>
                                <span class="text-danger">*</span>
                            </label>
                            <div class="input-group">
                                <select name="refund_method" id="refund_method" class="form-control" v-model="selectValue"  >
                                    <option :value="0">
                                    {{ $t('cancelPartialReservation.total') }}
                                    </option>
                                    <option :value="1">
                                         {{ $t('cancelPartialReservation.partial') }}
                                    </option>
                                    <option :value="2">
                                         {{ $t('cancelPartialReservation.noRefund') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div v-if="selectValue === 1" class="col-md-6">
                            <div>
                                <label>
                                    <span>
                                        {{ $t('cancelPartialReservation.amount') }}
                                    </span>
                                    <span class="text-danger">*</span>
                                </label>
                                <div class="input-group">
                                    <input type="number" v-model="basicAmount" class="form-control" />
                                </div>
                            </div>
     
                        </div>
                        <div class="col-md-12">
                            <div class="d-flex mt-5">
                                <label for="refund_method">
                                    <span>
                                        {{ $t('cancelPartialReservation.status') }}
                                    </span>
                                </label>
                                <div class="input-group">
                                    <b-form-checkbox size="lg" v-model="basicStatus" name="check-button" switch></b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('sales_invoices.items') }}</h6>
                <div class="d-flex">
                    <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
                </div>
                </div>
                <div class="table-responsive bg-white pb-5">
                    <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                        <thead>
                            <tr>
                                <th width="250px">{{ $t('items.item_details') }}</th>
                                <th width="300px">
                                    <div class="d-flex justify-content-between">
                                        <span>{{ $t('sales_invoices.item') }}</span>
                                    </div>
                                </th>
                                <th class="text-center" width="250px">{{ $t('financial_years.start_date') }}</th>
                                <th class="text-center" width="250px">{{ $t('financial_years.end_date') }}</th>
                                <th class="text-center" width="250px">{{ $t('notes') }}</th>
                                <th width="10px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in items_list" :key="index" >
                                <td>
                                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                                    <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                                    <div class="pl-2 pr-2 text-dark">
                                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                                        <p class="mb-1" v-if="row.item.available_stock_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.available_stock_qty }}</p>
                                    </div>
                                    </a>

                                </td>
                                <td>
                                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('estimations.item')">
                                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                                        <option value="name" selected>{{ $t('items.name') }}</option>
                                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                                        </select>
                                        <multiselect v-model="row.item"
                                                    :placeholder="$t('sales_invoices.item')"
                                                    :label="row.f_by? row.f_by:'name'"
                                                    track-by="id"
                                                    :options="items"
                                                    :multiple="false"
                                                    @input="selectItem(index)"
                                                    :taggable="false"
                                                    :show-labels="false"
                                                    :show-no-options="false"
                                                    :show-no-results="false"
                                                    :internal-search="false"
                                                    @search-change="getItems(row.f_by, $event)">
                                        </multiselect>
                                    </div>
                                </td>
                                <td class="text-center">
                                    2024-01-31
                                </td>
                                <td class="text-center">
                                    2024-01-31
                                </td>
                                <td class="text-center">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus aut earum, deleniti eos tempora asperiores quis tempore reprehenderit molestiae eum reiciendis eligendi recusandae quaerat vitae doloremque vero error temporibus voluptatem?
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="invoice-lines">
                        <ul>
                            <li>
                                <p>
                                    {{ $t('sales_invoices.total_amount_before_discount') }}:
                                </p>
                                <p>
                                    {{ data.total_amount_before_discount && data.total_amount_before_discount > 0 ? data.total_amount_before_discount.toFixed(2) : '0' }} {{ currency_code }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ $t('sales_invoices.total_amount_after_discount') }}:
                                </p>
                                <p>
                                    {{ data.total_amount_after_discount && data.total_amount_after_discount > 0 ? data.total_amount_after_discount.toFixed(2) : '0' }} {{ currency_code }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ $t('sales_invoices.total_discount') }}:
                                </p>
                                <p>
                                    {{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} {{ currency_code }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ $t('sales_invoices.total_after_shipping') }}:
                                </p>
                                <p>
                                    {{ data.shipping_fees ? data.shipping_fees : '0' }} {{ currency_code }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ $t('sales_invoices.total_tax') }}:
                                </p>
                                <p>
                                    {{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} {{ currency_code }}
                                </p>
                            </li>
                            <li>
                                <p colspan="2">{{ $t('sales_invoices.final_total') }}: <i class="fa fa-exclamation-circle ml-2 mr-2" v-b-tooltip.hover.leftbottom :title="$t('sales_invoices.total_amount_before_discount') + ' - ' + $t('sales_invoices.total_discount') + ' + ' + $t('sales_invoices.total_after_shipping') + ' + ' + $t('sales_invoices.total_tax')"></i></p>
                                <p>
                                    {{ data.is_zero_invoice == false ? data.invoice_total : sum_invoice_total }} {{ currency_code }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button type="reset" class="btn btn-primary mr-2">{{ $t('save') }}</button>
            </div>
        </div> 
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    export default {
        name:"CancelPartialReservation",
        data(){
            return {
                items: [],
                items_list: [],
                repeater_validation: [],
                currencies: [],
                currency_code: null,
                unit_default: null,
                items_list_form: {f_by: 'name', id: null, item: null, description: null, unit_price: null, qty: null, unit: null, tax: null, tax_2: null, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal: null, amount_tax: null, tax_percentage: null, units_number: null, tax_applied: [], subtotal_before_discount: null, 
                    subtotal_after_discount: null},
                data:{
                    total_amount_before_discount: 0,
                    total_amount_after_discount: 0,
                    invoice_total_discount: 0,
                    invoice_total_taxes: 0,
                    shipping_fees: null,
                    is_zero_invoice: false,
                    invoice_total: 0,
                },
                selectValue: 0,
                basicAmount: 0,
                basicStatus: false,
            }
        },
        computed:{
            id(){
                return this.$route.params.id
            },
            apiUrl(){
                return '/sales/sales_rents'
            },
            mainRouteDependency(){
                return '/base/dependency'
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            async getData() {
                const response = await ApiService.get(`${this.apiUrl}/${this.id}`);
                this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
                this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
                this.data.invoice_total_discount = response.data.data.invoice_total_discount;
                this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;
                this.data.shipping_fees = response.data.data.shipping_fees;
                this.data.invoice_total = response.data.data.invoice_total;                           
                this.data.inventory_id = response.data.data.inventory_id;

                this.items_list = response.data.data.items_list;

                this.getCurrencies(response.data.data.currency_id);
                this.getDefaultUnit()
            },

            addItemRowToList() {
                this.items_list_form.unit = this.unit_default;
                this.items_list.unshift(this.items_list_form);
                this.items_list_form = {f_by: 'name', id: null, item: null, description: null, unit_price: null, qty: null, unit: this.items_list_form.unit, tax: null, tax_2: null, discount_type: 1, discount_value: null, subtotal_before_tax: null, subtotal: null, amount_tax: null, tax_percentage: null, units_number: null, tax_applied: [], subtotal_before_discount: null, subtotal_after_discount: null};
                this.reindexingRepeaterValidation();
            },
            removeItemRowFromList(index = 0) {
                if (this.items_list.length > 1) {
                    this.items_list.splice(index, 1);
                    this.repeater_validation = this.repeater_validation.filter((val) => {
                    return index != val;
                    });
                    this.reindexingRepeaterValidation();
                }
            },
            reindexingRepeaterValidation() {
                if (this.repeater_validation.length > 0) {
                    this.repeater_validation = this.repeater_validation.map((val) => {
                        return val + 1;
                    });
                }
            },
            getItems(f_by = null, filter = null) {
                let _inventory_id = this.data.inventory_id;
                if (filter && filter.length >= 3 && !_inventory_id) {
                    this.$errorAlertMessage(this.$t('select_inventory_to_get_qty'));
                    return false;
                }

                let _f_by = f_by ? f_by : 'name';

                if (filter && filter.length >= 3 && _f_by){
                    ApiService.get("/base/dependency/items", {params: {[_f_by]: filter, inventory_id: _inventory_id}}).then((response) => {
                        this.items = response.data.data;
                    });
                }
            },
            getCurrencies(currency_id) {
                ApiService.get(`${this.mainRouteDependency}/currencies`).then((response) => {
                    this.currencies = response.data.data;
                    let _currency = this.currencies.find((row) => row.id == currency_id);
                    if (_currency) this.currency_code = _currency.code;
                });
            },
            getDefaultUnit() {
                ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
                    this.unit_default = response.data.data;
                });
            },

        },
    }
</script>

<style lang="scss" scoped>
.invoice-lines{
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    margin-top: 10px;
    p{
        margin: 0;
    }
    li{
        display: flex;
        align-items: center;
        gap: 130px;
        font-weight: bold;
        border-bottom:  1px solid #E5E5E5;
        padding: 10px 0;
        justify-content: space-between;
        &:last-child{
            border-bottom: unset;
        }
    }
}
</style>